<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header"></div>

          <div class="modal-body">
            <slot name="body">
              <template slot="title"> </template>

              <b-container fluid>
                <fieldset class="form-separator">
                  <legend style="width: 20%">Partenaire</legend>

                  <!--<div class="form-separator">-->
                  <b-row class="my-1 required">
                    <b-col sm="3">
                      <label for="inlineFormInputModalGroupUsername2"
                        >Partenaire</label
                      >
                    </b-col>
                    <b-col sm="9">
                      <b-form-input
                        :state="
                          partenaire.organisation != null &&
                          partenaire.organisation.length > 0
                        "
                        type="text"
                        placeholder="Nom de l'organisation partenaire"
                        v-model="partenaire.organisation"
                        id="inlineFormInputModalGroupUsername2"
                      />
                    </b-col>
                  </b-row>

                  <b-row class="my-1 required">
                    <b-col sm="3">
                      <label for="inlineFormInputModalGroupUsername5"
                        >Siret / ID Laboratoire</label
                      >
                    </b-col>
                    <b-col sm="9">
                      <b-form-input
                        type="text"
                        :state="
                          partenaire.siret != null &&
                          partenaire.siret.length > 0
                        "
                        placeholder="Siret / ID Laboratoire"
                        v-model="partenaire.siret"
                        id="inlineFormInputModalGroupUsername5"
                      />
                    </b-col>
                  </b-row>

                  <b-row class="my-1 required">
                    <b-col sm="3">
                      <label for="Rue">Rue</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-input
                        type="text"
                        :state="
                          partenaire.rue != null && partenaire.rue.length > 0
                        "
                        placeholder="Rue"
                        v-model="partenaire.rue"
                        id="Rue"
                      />
                    </b-col>
                  </b-row>

                  <b-row class="my-1 required">
                    <b-col sm="3">
                      <label for="Ville">Ville</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-input
                        type="text"
                        :state="
                          partenaire.ville != null &&
                          partenaire.ville.length > 0
                        "
                        placeholder="Ville"
                        v-model="partenaire.ville"
                        id="Ville"
                      />
                    </b-col>
                  </b-row>

                  <b-row class="my-1 required">
                    <b-col sm="3">
                      <label for="inlineFormInputModalGroupUsername4"
                        >Code postal</label
                      >
                    </b-col>
                    <b-col sm="9">
                      <b-form-input
                        type="text"
                        :state="
                          partenaire.cp != null && partenaire.cp.length > 0
                        "
                        placeholder="Code postal"
                        v-model="partenaire.cp"
                        id="inlineFormInputModalGroupUsername4"
                      />
                    </b-col>
                  </b-row>
                  <!--</div>-->
                </fieldset>

                <fieldset class="form-separator">
                  <legend style="width: 20%">Contact</legend>
                  <!--<div class="form-separator">-->
                  <b-row class="my-1 required">
                    <b-col sm="3">
                      <label for="inlineFormInputModalGroupnom2"
                        >Nom du contact</label
                      >
                    </b-col>
                    <b-col sm="9">
                      <b-form-input
                        :state="
                          partenaire.nom != null && partenaire.nom.length > 0
                        "
                        type="text"
                        placeholder="Nom du contact"
                        v-model="partenaire.nom"
                        id="inlineFormInputModalGroupnom2"
                      />
                    </b-col>
                  </b-row>

                  <b-row class="my-1 required">
                    <b-col sm="3">
                      <label for="inlineFormInputModalGroupprenom2"
                        >Prenom du contact</label
                      >
                    </b-col>
                    <b-col sm="9">
                      <b-form-input
                        :state="
                          partenaire.prenom != null &&
                          partenaire.prenom.length > 0
                        "
                        type="text"
                        placeholder="Prenom du contact"
                        v-model="partenaire.prenom"
                        id="inlineFormInputModalGroupprenom2"
                      />
                    </b-col>
                  </b-row>

                  <b-row class="my-1 required">
                    <b-col sm="3">
                      <label for="Fonction">Fonction</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-input
                        type="text"
                        :state="
                          partenaire.fonction != null &&
                          partenaire.fonction.length > 0
                        "
                        placeholder="Fonction"
                        v-model="partenaire.fonction"
                        id="Fonction"
                      />
                    </b-col>
                  </b-row>

                  <b-row class="my-1 required">
                    <b-col sm="3">
                      <label for="inlineFormInputModalGroupUsername6"
                        >E-mail</label
                      >
                    </b-col>
                    <b-col sm="9">
                      <!--todo :state not working with api???? check-->
                      <b-form-input
                        @input="checkValidity"
                        type="email"
                        placeholder="E-mail"
                        :state="validMail"
                        v-model="partenaire.mail"
                        id="inlineFormInputModalGroupUsername6"
                        aria-describedby="inputLiveFeedback"
                      />
                      <b-form-invalid-feedback id="inputLiveFeedback">
                        {{ mailErrorMsg }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>

                  <b-row class="my-1 required">
                    <b-col sm="3">
                      <label for="inlineFormInputModalGroupUsername7"
                        >Téléphone</label
                      >
                    </b-col>
                    <b-col sm="9">
                      <b-form-input
                        type="text"
                        placeholder="Téléphone"
                        :state="
                          partenaire.tel != null && partenaire.tel.length > 0
                        "
                        v-model="partenaire.tel"
                        id="inlineFormInputModalGroupUsername7"
                      />
                    </b-col>
                  </b-row>

                  <!--</div>-->
                </fieldset>
              </b-container>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <!--
                <button class="modal-default-button" @click="$emit('SelectInputStatus', 'second')">
              -->

              <button
                class="btn btn-danger"
                @click="$emit('showPartnerModal', false)"
              >
                Annuler
              </button>

              &nbsp;&nbsp;&nbsp;
              <div v-if="fullPartnerForm">
                <button
                  v-if="Object.keys(partnerToEdit).length === 0"
                  class="btn btn-success"
                  @click="addPartner"
                >
                  <span>Ajouter</span>
                </button>

                <button v-else class="btn btn-success" @click="modifyPartner">
                  <span>Modifier</span>
                </button>
              </div>

              <!--$emit('interface', 'main')-->
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";

export default {
  props: {
    partnerToEdit: Object,
  },

  data: function () {
    return {
      validMail: false,
      mailErrorMsg: "",
      partenaire: {
        organisation: null,
        nom: null,
        prenom: null,
        rue: null,
        ville: null,
        fonction: null,
        cp: null,
        siret: null,
        mail: null,
        tel: null,
      },
    };
  },

  computed: {
    fullPartnerForm() {
      if (
        this.partenaire.nom &&
        this.partenaire.prenom &&
        this.partenaire.organisation &&
        this.partenaire.rue &&
        this.partenaire.ville &&
        this.partenaire.fonction &&
        this.partenaire.cp &&
        this.partenaire.siret &&
        this.partenaire.tel &&
        this.validMail
        // this.general_conditions.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    checkValidity(email) {
      console.log(email);
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
      this.mailErrorMsg = "";

      if (re.test(String(email).toLowerCase())) {
        axios.get("/api/exist_mail/" + email).then((response) => {
          this.validMail = response.data;
          if (!this.validMail) {
            this.mailErrorMsg = "Cette adresse mail est déja utilisée";
          }
        });
      } else {
        this.validMail = false;
        this.mailErrorMsg = "Addresse mail incomplète ou mal formée";
      }
    },

    addPartner() {
      this.$emit("AddedPartner", this.partenaire);
      this.$emit("showPartnerModal", false);
    },

    modifyPartner() {
      this.$emit("ModifiedPartner", this.partenaire);
      this.$emit("showPartnerModal", false);
    },
  },

  created() {
    if (Object.keys(this.partnerToEdit).length > 0) {
      this.validMail = true;
      this.mailErrorMsg = "";
      this.partenaire = this.partnerToEdit;
    }
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  overflow: auto;
}

.modal-container {
  width: 75%;
  height: 90%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.required label {
  font-weight: bold;
}

/*todo essayer before input*/
.required label:after {
  color: #e32;
  content: " *";
  display: inline;
}

.form-separator {
  border: 1px solid #80808036;
  border-radius: 20px;
  padding: 1% 1%;
}
</style>
