<template>
  <div style="margin: 12% 5%">
    <h3 align="center" style="margin-bottom: 2em">
      Votre réponse à:
      <b style="margin-left: 1%">{{ amiResponse.titre_ami }}</b>
    </h3>
    <div>
      <table width="100%">
        <tr>
          <td>
            <b-form-radio-group
              style="margin-left: 8%"
              id="btnradios2"
              buttons
              button-variant="outline-primary"
              size="md"
              v-model="selected_reponse_type"
              :options="reponse_options"
              name="radioBtnOutline"
            />
          </td>
          <td>
            <div class="text-center mb-3">
              <em>
                Vous devez remplir tout le formulaire avant de pouvoir de
                valider et soumettre votre candidature.
              </em>
            </div>
          </td>
        </tr>
      </table>

      <div style="margin: 1%"></div>

      <!--<div style="margin-left: 40%;">-->
      <!--<span style="font-size: 15px; font-style: italic;}">-->
      <!--Vous devez remplir tout le formulair afin de valider et soumettre votre candidature-->
      <!--</span>-->
      <!--</div>-->
    </div>

    <b-card no-body>
      <b-tabs v-model="tabIndex" pills card vertical>
        <b-tab
          :title-link-class="linkClass(0)"
          title="Informations candidat"
          active
        >
          <template slot="title">
            <!--                amiResponse.mail &&-->
            <img
              v-if="
                amiResponse.candidate &&
                amiResponse.rue &&
                amiResponse.ville &&
                amiResponse.nom &&
                amiResponse.prenom &&
                amiResponse.fonction &&
                amiResponse.cp &&
                amiResponse.siret &&
                amiResponse.tel
              "
              src="/static/img/success.png"
              style="width: 20px"
              alt=""
            />

            <b-spinner v-else type="grow" variant="danger" small />

            Informations candidat
          </template>

          <b-container fluid>
            <fieldset class="form-separator">
              <legend style="width: 20%">Organisation</legend>

              <b-row class="my-1 required">
                <b-col sm="3">
                  <label for="inlineFormInputGroupUsername2"
                    >Organisation</label
                  >
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    :state="
                      amiResponse.candidate != null &&
                      amiResponse.candidate.length > 0
                    "
                    type="text"
                    placeholder="Nom de l'organisation partenaire"
                    v-model="amiResponse.candidate"
                    id="inlineFormInputGroupUsername2"
                  />
                </b-col>
              </b-row>

              <b-row class="my-1 required">
                <b-col sm="3">
                  <label for="inlineFormInputGroupUsername5"
                    >Siret / ID Laboratoire</label
                  >
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    type="text"
                    :state="
                      amiResponse.siret != null && amiResponse.siret.length > 0
                    "
                    placeholder="Siret / ID Laboratoire"
                    v-model="amiResponse.siret"
                    id="inlineFormInputGroupUsername5"
                  />
                </b-col>
              </b-row>

              <b-row class="my-1 required">
                <b-col sm="3">
                  <label for="Rue">Rue</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    type="text"
                    :state="
                      amiResponse.rue != null && amiResponse.rue.length > 0
                    "
                    placeholder="Rue"
                    v-model="amiResponse.rue"
                    id="Rue"
                  />
                </b-col>
              </b-row>

              <b-row class="my-1 required">
                <b-col sm="3">
                  <label for="Ville">Ville</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    type="text"
                    :state="
                      amiResponse.ville != null && amiResponse.ville.length > 0
                    "
                    placeholder="Ville"
                    v-model="amiResponse.ville"
                    id="Ville"
                  />
                </b-col>
              </b-row>

              <b-row class="my-1 required">
                <b-col sm="3">
                  <label for="inlineFormInputGroupUsername4">Code postal</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    type="text"
                    :state="amiResponse.cp != null && amiResponse.cp.length > 0"
                    placeholder="Code postal"
                    v-model="amiResponse.cp"
                    id="inlineFormInputGroupUsername4"
                  />
                </b-col>
              </b-row>
            </fieldset>

            <fieldset class="form-separator">
              <legend style="width: 20%">Contact</legend>

              <b-row class="my-1 required">
                <b-col sm="3">
                  <label for="NomCandidat">Nom</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    :state="
                      amiResponse.nom != null && amiResponse.nom.length > 0
                    "
                    type="text"
                    placeholder="Nom du contact"
                    v-model="amiResponse.nom"
                    id="NomCandidat"
                  />
                </b-col>
              </b-row>

              <b-row class="my-1 required">
                <b-col sm="3">
                  <label for="PrenomCandidat">Prenom</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    :state="
                      amiResponse.prenom != null &&
                      amiResponse.prenom.length > 0
                    "
                    type="text"
                    placeholder="Prénom du contact"
                    v-model="amiResponse.prenom"
                    id="PrenomCandidat"
                  />
                </b-col>
              </b-row>

              <b-row class="my-1 required">
                <b-col sm="3">
                  <label for="Fonction">Fonction</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    type="text"
                    :state="
                      amiResponse.fonction != null &&
                      amiResponse.fonction.length > 0
                    "
                    placeholder="Fonction"
                    v-model="amiResponse.fonction"
                    id="Fonction"
                  />
                </b-col>
              </b-row>

              <b-row class="my-1 required">
                <b-col sm="3">
                  <label for="inlineFormInputGroupUsername7">Téléphone</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    type="text"
                    placeholder="Téléphone"
                    :state="
                      amiResponse.tel != null && amiResponse.tel.length > 0
                    "
                    v-model="amiResponse.tel"
                    id="inlineFormInputGroupUsername7"
                  />
                </b-col>
              </b-row>
            </fieldset>
          </b-container>

          <!-- Control buttons-->
          <div class="text-center" style="margin-top: 2em">
            <!--<b-button class="btn btn-danger" @click="tabIndex&#45;&#45;">Annuler</b-button>-->
            <!--&nbsp;&nbsp;-->

            <b-button v-if="show_loading_btn" class="btn btn-primary" disabled>
              <b-spinner small></b-spinner>
              Chargement...
            </b-button>

            <b-button v-else class="btn btn-primary" @click="save()"
              >Sauvegarder
            </b-button>
          </div>
        </b-tab>

        <b-tab
          v-if="selected_reponse_type == 'consortium'"
          :title-link-class="linkClass(1)"
          title="Partenaires"
        >
          <template slot="title">
            <img
              v-if="partenaires && partenaires.length > 0"
              src="/static/img/success.png"
              style="width: 20px"
              alt=""
            />

            <b-spinner v-else type="grow" variant="danger" small />

            Partenaires
          </template>

          <b-container fluid>
            <b-row
              v-for="(partenaire, indx) in partenaires"
              :key="'partenaire' + indx"
              class="partenaires-separator my-1"
            >
              <!--id -> {{indx}}-->
              <!--partenaire -> {{partenaire}}-->
              <b-col style="margin-top: 5px" sm="3">
                <label :for="partenaire + indx"
                  >Partenaire {{ indx + 1 }}</label
                >
              </b-col>
              <b-col style="margin-top: 5px" sm="7">
                <!--<b-form-input-->
                <!--type="email"-->
                <!--placeholder="E-mail"-->
                <!--:state="validateEmail(partenaires[indx])"-->
                <!--v-model="partenaires[indx]"-->
                <!--:id="partenaire + indx"-->
                <!--/>-->
                <span>
                  <b>{{ partenaires[indx].organisation }}</b>
                </span>
              </b-col>

              <button class="btn btn-default" @click="EditPartenaire(indx)">
                <i
                  style="color: green; font-size: 15px"
                  class="fas fa-edit"
                ></i>
              </button>
              &nbsp;&nbsp;
              <button
                class="btn btn-default"
                @click="SupprimerPartenaire(indx)"
              >
                <i
                  style="color: #ac270e; font-size: 15px"
                  class="far fa-trash-alt"
                ></i>
              </button>
            </b-row>

            <button class="btn btn-default" @click="AjouterPartenaire">
              <i
                style="color: green; font-size: 22px"
                class="fas fa-plus-circle"
              ></i>
            </button>
          </b-container>

          <br />

          <b-button
            v-if="show_loading_btn"
            style="margin-left: 35%"
            class="btn btn-primary"
            disabled
          >
            <b-spinner small></b-spinner>
            Chargement...
          </b-button>

          <b-button
            v-else
            style="margin-left: 35%"
            class="btn btn-primary"
            @click="save()"
            >Sauvegarder
          </b-button>
        </b-tab>

        <b-tab
          :title-link-class="
            linkClass(selected_reponse_type === 'individuelle' ? 1 : 2)
          "
          title="Pièces jointe"
        >
          <template slot="title">
            <img
              v-if="proposition_du_candidat.length > 0"
              src="/static/img/success.png"
              style="width: 20px"
              alt=""
            />

            <b-spinner v-else type="grow" variant="danger" small />

            Pièce jointe
          </template>

          <b-container fluid>
            <b-row class="my-1 required">
              <b-col sm="3">
                <label for="file-upload1"
                  >Proposition du candidat<br />(PDF ou ZIP, max 10 Mo)</label
                >
              </b-col>
              <b-col sm="9">
                <file-pond
                  id="file-upload1"
                  allowFileSizeValidation="true"
                  maxFileSize="10MB"
                  labelMaxFileSizeExceeded="Le fichier est trop grand"
                  labelMaxFileSize="La taille maximale du fichier est {filesize}"
                  name="proposition_du_candidat"
                  ref="pond"
                  label-idle="Déposez le fichier ici..."
                  accepted-file-types="application/pdf,application/zip,application/x-zip-compressed"
                  :server="getSaveFileUrl(proposition_du_candidat)"
                  @processfile="
                    proposition_du_candidat = ['proposition_du_candidat']
                  "
                />
                <a
                  v-if="this.amiResponse.proposition_du_candidat"
                  href="download/proposition_du_candidat"
                >
                  <span>Télécharger votre fichier</span></a
                >
              </b-col>
            </b-row>
          </b-container>
          <br />
        </b-tab>
      </b-tabs>
    </b-card>

    <div v-if="fullAmiResponse">
      <h5 class="mt-5" style="margin: 0% 34%; width: 44%">
        Vous êtes prêt à soumettre votre candidature
      </h5>

      <b-button
        @click="validerCandidature"
        style="width: 25%; margin: 1% 0% 4% 35%"
        variant="primary"
      >
        <b>Relire et valider ma candidature</b>
      </b-button>

      <!-- TODO -->
      <!--<div style="margin: 0 0 5% 10%">-->
      <!--<b-button-->
      <!--style="width: 20%;  margin-left: 15%"-->
      <!--variant="outline-success"-->
      <!--&gt;Exporter en pdf-->
      <!--</b-button>-->
      <!--<b-button-->
      <!--style="width: 20%; margin-left: 15%"-->
      <!--variant="outline-danger"-->
      <!--&gt;Supprimer-->
      <!--</b-button>-->
      <!--</div>-->
    </div>

    <partner-modal
      :partnerToEdit="partnerToEdit"
      @AddedPartner="partenaires.push($event)"
      @ModifiedPartner="
        partenaires[$event.idx] = $event;
        partnerToEdit = {};
      "
      @showPartnerModal="showPartnerModal = $event"
      v-if="showPartnerModal"
    ></partner-modal>
  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// Import file size validation plugins
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

import PartnerModal from "./PartnerModal";
import axios from "axios";

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);

export default {
  data: function () {
    return {
      show_soumettre_toast: true,
      show_loading_btn: false,
      tabIndex: 0,
      // general_conditions: [],
      showPartnerModal: false,
      AmiId: null,
      reponse_options: [
        { text: "Individuelle", value: "individuelle" },
        { text: "Consortium", value: "consortium" },
      ],
      partenaires: [],
      proposition_du_candidat: [],
      partnerToEdit: {},
      selected_reponse_type: "individuelle",
      amiResponse: {
        submitted: false,
        titre_ami: null,
        nom: null,
        prenom: null,
        candidate: null,
        ville: null,
        rue: null,
        fonction: null,
        cp: null,
        siret: null,
        // mail: null,
        tel: null,
      },
    };
  },

  methods: {
    AjouterPartenaire() {
      if (this.partenaires[this.partenaires.length - 1] != "") {
        this.showPartnerModal = true;
        this.partnerToEdit = {};
        // this.partenaires.push({
        //   'nom': '',
        //   'fonction': '',
        //   'rue': '',
        //   'ville': '',
        //   'cp': '',
        //   'siret': '',
        //   'email': '',
        //   'tel': '',
        //
        // })
      }
    },

    EditPartenaire(index) {
      this.partnerToEdit = this.partenaires[index];
      this.partnerToEdit.idx = index;
      this.showPartnerModal = true;
    },

    SupprimerPartenaire(index) {
      this.partenaires.splice(index, 1);
    },

    getSaveFileUrl(fileType) {
      return "/api/save_file?file=" + fileType + "&ami=" + this.AmiId;
    },

    // invite() {
    //   axios.post("/api/invite_partners", this.partenaires).then(response => {
    //     if (response.data.success == true) {
    //       this.$notify({
    //         group: "user",
    //         title: "Invitations envoyées avec succès",
    //         type: "success",
    //       });
    //     } else {
    //       this.$notify({
    //         group: "user",
    //         title: "Erreur lors d'envoi des invtations",
    //         type: "error",
    //       });
    //     }
    //   });
    // },
    save() {
      const vue = this;

      this.show_loading_btn = true;
      if (this.selected_reponse_type == "consortium") {
        this.amiResponse.partenaires = this.partenaires;
      } else {
        this.amiResponse.partenaires = [];
      }

      this.amiResponse.reponse_type = this.selected_reponse_type;
      const response = this.amiResponse;
      response.send_confirmation_mail = false;
      axios
        .post("/api/submit_reponse", response)
        .then((response) => {
          if (response.data.success == true) {
            // force change url
            vue.show_loading_btn = false;
            vue.$notify({
              group: "user",
              title: "Sauvegardé avec succès",
              type: "success",
            });
          } else {
            vue.show_loading_btn = false;
            vue.$notify({
              group: "user",
              title: "Erreur de sauvegarde (" + response.data.message + ")",
              type: "error",
            });
          }
        })
        .catch(function () {
          vue.show_loading_btn = false;
          vue.$notify({
            group: "user",
            title:
              "Ooops, une erreur est survenue, veuillez réessayer ulterieurement",
            type: "error",
          });
        });

      // if (next_onglet) {
      //   this.tabIndex++;
      // }
    },

    validerCandidature() {
      this.$emit("ToValidate", true);
      this.$emit("afficherDetailCandidature", true);
      if (this.selected_reponse_type == "consortium") {
        this.amiResponse.partenaires = this.partenaires;
      }
      this.$emit("amiResponse", this.amiResponse);
    },

    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-primary", "text-light"];
      } else {
        return ["bg-light", "text-info"];
      }
    },
    // validateEmail(email) {
    //   var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    //   return re.test(String(email).toLowerCase());
    // },
  },

  computed: {
    fullAmiResponse() {
      /* eslint-disable */
      if (
        this.amiResponse.candidate &&
        this.amiResponse.rue &&
        this.amiResponse.nom &&
        this.amiResponse.prenom &&
        this.amiResponse.ville &&
        this.amiResponse.fonction &&
        this.amiResponse.cp &&
        this.amiResponse.siret &&
        this.amiResponse.tel &&
        this.proposition_du_candidat.length > 0
      ) {
        if (this.show_soumettre_toast && !this.amiResponse.submitted) {
          this.show_soumettre_toast = false;
          this.$dlg.toast(
            "Formulaire bien rempli, n'oubliez pas de le soumettre !",
            {
              closeTime: 7,
              position: "topRight",
              messageType: "warning",
              language: "en",
            }
          );
        }
        return true;
      } else {
        this.show_soumettre_toast = true;
        return false;
      }
      /* eslint-enable */
    },
  },

  components: {
    FilePond,
    PartnerModal,
  },

  created() {
    const response_id =
      this.$route.path.split("/")[this.$route.path.split("/").length - 2];
    if (parseInt(response_id)) {
      axios.get("/api/get_reponse/" + response_id).then((response) => {
        this.amiResponse = response.data;
        if (this.amiResponse.reponse_type) {
          this.selected_reponse_type = this.amiResponse.reponse_type;
        } else {
          // pas imporant mais pour etre sure
          this.selected_reponse_type = "individuelle";
        }

        this.partenaires = response.data.partners;
        this.$emit("selected_reponse_type", response.data.reponse_type);
        // todo le fichier est enregistré dans la bd en bytes seuelements, je connais pas son nom
        // if (response.data.conditions_generales) {
        //   this.general_conditions = ["Telecharger votre fichier"];
        // }
        if (response.data.proposition_du_candidat) {
          this.proposition_du_candidat = ["Télécharger votre fichier"];
        }
      });
    }
    this.AmiId =
      this.$route.path.split("/")[this.$route.path.split("/").length - 2];
  },
};
</script>

<style scoped>
.required label {
  font-weight: bold;
}

/*todo essayer before input*/
.required label:after {
  color: #e32;
  content: " *";
  display: inline;
}

.form-separator {
  border: 1px solid #80808036;
  border-radius: 20px;
  padding: 1% 1%;
}

.partenaires-separator {
  border: 1px solid #80808036;
  border-radius: 20px;
  padding: 1% 1%;
}
</style>
